<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}">
      <v-card-title
        >{{ action.name ? action.name : action.action_id }}
        <v-progress-circular
          indeterminate
          v-if="loading"
          color="primary"
        ></v-progress-circular>
        <v-spacer></v-spacer>
        <span
          v-if="
            !$store.state.clientSafety &&
            $store.state.user &&
            $store.state.settingMode &&
            $store.getters.getAccessByLevels(['engineeraccess'])
          "
        >
          <v-icon
            small
            class="mr-2"
            v-if="
              $store.state.user &&
              Number($store.state.user.user_level) >
                $store.state.betaAccessLevel
            "
            @click="showEdit = !showEdit"
            color="blue"
          >
            mdi-pencil
          </v-icon>

          <DeleteConfirmation
            v-on:confirm="deleteAction()"
            title="Delete Confirmation"
            description="Are you sure you want to delete this action?"
          >
            <v-icon
              small
              :color="$store.getters.getColorPalette().deletebtnColor"
            >
              mdi-delete
            </v-icon>
          </DeleteConfirmation>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-row v-if="showEdit && action.type == 'notification'">
        <v-col>
          <CreateNotificationAction
            :action="action"
            v-on:close="showEdit = false"
          />
        </v-col>
      </v-row>
      <v-row v-if="showEdit && action.type == 'parameter'">
        <v-col>
          <CreateParameterAction
            :action="action"
            v-on:close="showEdit = false"
          />
        </v-col>
      </v-row>
      <v-row v-if="showEdit && action.type == 'relay'">
        <v-col>
          <CreateRelayAction :action="action" v-on:close="showEdit = false" />
        </v-col>
      </v-row>
      <v-card-subtitle>
        <v-row v-if="action.type == 'relay' && !loading">
          <v-col> <strong>{{$store.getters.getTextMap().configured_hub}}:</strong> {{ target["hub"] }} </v-col>
          <v-col>
            <strong>{{$store.getters.getTextMap().configured_relay}}:</strong> {{ target["relayType"] }} Relay
            {{ target["relay"] }}
          </v-col>
          <v-col>
            <strong>{{$store.getters.getTextMap().desired_state}}:</strong> {{ target["state"] ? "On" : "Off" }}
          </v-col>
        </v-row>
        <v-row v-if="action.type == 'notification' && !loading">
          <v-col>
            <strong>{{$store.getters.getTextMap().configured_notification_group}}:</strong>
            {{
              $store.getters.getNotificationGroupById(action.target)
                ? $store.getters.getNotificationGroupById(action.target)["name"]
                : "Nothing configured"
            }}
          </v-col>
        </v-row>
        <v-row v-else-if="action.type == 'parameter' && !loading">
          <v-col>
            {{ device.name }}
          </v-col>
          <v-col> <strong>{{$store.getters.getTextMap().address}}:</strong> {{ address }} </v-col>
          <v-col> <strong>{{$store.getters.getTextMap().data}}:</strong> {{ values }} </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
import CreateNotificationAction from "@/components/crud_components/actions/CreateNotificationAction";
import CreateParameterAction from "@/components/crud_components/actions/CreateParameterAction";
import CreateRelayAction from "@/components/crud_components/actions/CreateRelayAction";
import DeleteConfirmation from "@/components/DeleteConfirmation";
export default {
  name: "Action",
  props: ["action"],
  components: {
    InfoAlert,
    CreateNotificationAction,
    CreateParameterAction,
    CreateRelayAction,
    DeleteConfirmation,
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      showEdit: false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
    };
  },

  computed: {
    address() {
      return this.action.target && this.action.target.address
        ? this.action.target.address
        : "-";
    },
    device() {
      if (this.action.target && this.action.target.device_id) {
        return this.$store.getters.getDeviceById(this.action.target.device_id);
      } else {
        return "-";
      }
    },
    values() {
      return this.action.target && this.action.target.data
        ? this.action.target.data
        : "-";
    },
    target() {
      let target = {};
      if (this.action.type == "relay" && this.action.target) {
        target = JSON.parse(this.action.target);
      }
      return target;
    },
  },

  methods: {
    deleteAction() {
      this.loading = true;
      axios
        .post(this.$store.state.api + "deleteAction", this.action, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshActions");
            this.loading = false;
            this.info = "The Action has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  background-color: #4db6ac;
}
</style>
