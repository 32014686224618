var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{style:({
      'border-radius': '5px',
      border:
        '1px solid' + _vm.$store.getters.getColorPalette().panelBorderColorCode,
    }),attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":"","dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.triggers,"item-key":"trigger_id","search":_vm.search,"single-expand":true,"show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-class":"font-weight-black text-center","dark":_vm.$store.getters.getColorPalette().isDark},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().triggers))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('Trigger',{attrs:{"trigger":item}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }