<template>
    <v-container>
       
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<!-- {{action}}
{{paramWrite}} -->
<v-form
            ref="parameterForm"
            v-model="parameterValid"
         
        >
        <v-row>
            
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="paramWrite.workspace"
          :items="workspaceOptions"
          label="Workspace"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>  
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="paramWrite.device"
          :items="deviceOptions"
          label="Device"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
        </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="form.name"
                        :counter="15"
                        :rules="nameRules"
                        label="Name"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="paramWrite.address"
                        :counter="5"
                        :rules="nonNegativeRules"
                        label="address"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="paramWrite.offset"
                        :counter="1"
                        :rules="nonNegativeRules"
                        label="Number of registers"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="item in valueItems" :key="item">
                    <v-text-field
                    :loading="loading"
                    v-model="paramWrite.values[item]"
                    :counter="5"
                    :rules="numberRules"
                    label="Value"
                    required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                       
                        @click="parameterSubmit"
                    >
                        Submit
                    </v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                </v-col>
            </v-row>
    
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateParameterAction',
     props:['action'],
    components:{
        InfoAlert
    },

    mounted(){
        if(this.action && this.action.action_id){
            this.api='updateActions'
            this.editMode=true
            this.form=Object.assign({},this.action)
           //console.log(this.form,'this.form')
           let target=JSON.parse(this.action.target)
           this.paramWrite['device']=this.$store.getters.getDeviceById(target['device_id'])
           this.paramWrite['address']=target['address']
           this.paramWrite['offset']=target['offset']
           this.paramWrite['values']=target['data']
           this.paramWrite['workspace']=this.$store.getters.getWorkspaceById(this.paramWrite['device']['workspace_id'])
           //this.paramWrite['device']=this.$store.getters.getDeviceById(['device_id'])
      }else{
            this.api='createAction'
            this.editMode=false
        }
       
    },
    data(){
        return {
            api:'createAction',
            editMode:false,
            loading:false,
            parameterValid:false,
            showDismissibleAlert:false,
            info:'',
      
            paramWrite:{
                address:null,
                values:[],
                offset:null,
            },
               form:{
                type:'notification',
                target:[]
            },
      
             objectRules:[
                v => !!v || 'Required',
            ],
              nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
          
            numberRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
            nonNegativeRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
             types:[
                {label:"Send Notification", value:'notification'},
                {label:"Write Parameter", value:'parameter'},
                {label:"Relays", value:'relay'},
            ],
        }
    },
    computed:{
        valueItems(){
            if(this.paramWrite.offset && Number.isInteger(Number(this.paramWrite.offset)) && Number(this.paramWrite.offset) <= 8){
                let items=[]
                for (let i=0;i<Number(this.paramWrite.offset);i++){
                    items.push(i)
                }
                return items
            }else{
                return []
            }
        },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },  
        deviceOptions(){
             
            let op=[]
          
          if(this.paramWrite.workspace && this.paramWrite.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.paramWrite.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }  
          return op
        },  
          hubList(){
            let op=[]
            for(let i of this.$store.state.hubs){
                op.push({label:i.hub_id,value:i.hub_id})
            }
            return op
        } 
    },
    methods:{
         close(){
            this.$emit("close")
        },
      parameterSubmit(){
            this.$refs.parameterForm.validate()
            if(this.parameterValid){
                this.loading=true
                let d=Object.assign({}, this.form)
              //console.log(this.form,"form")
                d.type='parameter'
                d.target={}
                d.target['data']=[]
                for(let i=0;i<Number(this.paramWrite.offset);i++){
                    d.target['data'].push(this.paramWrite.values[i])
                }
                d.target['device_id']=this.paramWrite.device.device_id
                d.target['address']=this.paramWrite.address
                d.target['offset']=this.paramWrite.offset
               
            if(this.action && this.action.action_id){
                this.api='updateActions'
                d['action_id']=this.action.action_id
            }else{
                    this.api='createAction'
            }
           // console.log(d)
          
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshActions')
                             this.info='Action created'
                            this.showDismissibleAlert=true
                           this.close()
                            
                           
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                              this.info='Changes Updated'
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
                

            }
        }
    }
}
</script>