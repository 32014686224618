<template>

    <v-container>
       
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<!-- {{action}}
{{form}} -->
 <v-form
            ref="emailForm"
            v-model="emailValid"
            v-if="form.type=='notification'"
            
        >
  
        <v-row>
            <v-col>
                <v-text-field
                v-if="!loading"
      v-model="form.name"
      :counter="15"
      :rules="nameRules"
      label="Name"
      required
    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
                v-if="!loading"
              v-model="form.target"
          :items="notificationGroupList"
          label="Notification Group"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                         :color="$store.getters.getColorPalette().submitbtnColor"
                        outlined
                        @click="emailSubmit"
                    >
                        Submit
                    </v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                      :dark="$store.getters.getColorPalette().isDark"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateNotificationAction',
    props:['action'],
    components:{
        InfoAlert
    },
    mounted(){
        console.log(this.action,"action")
        if(this.action && this.action.action_id){
            this.api='updateActions'
            this.editMode=true
            this.form=Object.assign({},this.action)
            // this.emailForm.name=this.action.name,
            // this.emailForm.target=this.action.target
        }else{
            this.api='createAction'
            this.editMode=false
        }

    },
    data(){
        return {
            api:'createAction',
            editMode:false,
            loading:false,
            emailValid:false,
            showDismissibleAlert:false,
            emails:'',
            info:'',
            form:{
                type:'notification',
                target:[]
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
             types:[
                {label:"Send Notification", value:'notification'},
                {label:"Write Parameter", value:'parameter'},
                {label:"Relays", value:'relay'},
            ],
            
        }
    },
     
    computed:{
        notificationGroupList(){
            let op=[]
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
            return op
        },
    },
    methods:{
         close(){
            this.$emit("close")
        },
       emailSubmit(){
            this.$refs.emailForm.validate()
            if(this.emailValid && this.form.type=='notification'){
                this.loading=true
                let d=Object.assign({}, this.form)
                //d.target=this.emails.replaceAll(' ','').split(',')
                axios.post(this.$store.state.api+this.api ,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshActions')
                            this.$refs.emailForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                         this.showDismissibleAlert=true
                        
                     })
             }
         },
    }
}
</script>