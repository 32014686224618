<template>
  <div class="text-center">
    <v-btn
    class="alarm"
      
     :isDark="$store.getters.getColorPalette().isDark"
    :background-color="$store.getters.getColorPalette().background1ColorCode"
    :color="$store.getters.getColorPalette().accentCode"
    outlined
      small
      @click="dialog = !dialog"
    >
      {{ $store.getters.getTextMap().create_action }} <v-icon
        right
        :isDark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().btnborderColorCode"
        small
      >mdi-gesture-tap
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
       :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"
      >
        
       <CreateAction v-on:close="dialog=false" />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateAction from '@/components/crud_components/CreateAction'

export default {
    name:'CreateActionBottomSheet',
    components:{
        CreateAction
    },
    data(){
        return {
            dialog:false,
                
      }
    },
    
}
</script>
<style scoped>
.alarm{
   border:3px solid;
  border-radius:20px;
}

.theme--light.v-sheet{
  background-color: teal;
}
</style>
