<template>
  <v-container fluid>    
    <v-card
      :style="{
        'border-radius': '5px',
        border:
          '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
      }"
      flat
      :dark="$store.getters.getColorPalette().isDark"
    >
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          :dark="$store.getters.getColorPalette().isDark"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="triggers"
        item-key="trigger_id"
        :search="search"
        :single-expand="true"
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
        loading-text="Loading... Please wait"
        item-class="font-weight-black text-center"
        :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{$store.getters.getTextMap().triggers}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <Trigger :trigger="item" />
          </td>
        </template>
        
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Trigger from "@/components/items/Trigger";
import axios from 'axios';

//import Trigger from '../items/Trigger.vue'
export default {
  name: "TriggerTable",
  components: {
    Trigger,
  },
  data() {
    return {
      search: "",
      loading: false,
      triggeredParamData:null,
      tableData:[],
      expanded: [],
      check:null,
      headers: [
        {
          text: "ID",
          align: "start",
          filterable: false,
          value: "trigger_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Name",
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        // { text: 'Operator', value: 'operator', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName},
        // { text: 'Value', value: 'value', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName},
        {
          text: "Wait Time",
          value: "timeout",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Triggered Params Count",
          value: "count",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  computed: {
    triggers() {
      //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))

     let  triggers = this.$store.getters.getTriggers();
     if(this.triggeredParamData){
      triggers.forEach(element => {
      let temp=this.triggeredParamData.filter(d=>d.trigger_id==element.trigger_id)
      let count=0
      if(temp && temp.length){
        count=temp.length
      }
      element.count=count
     });
     }
      return triggers
    },


  },

  mounted(){
  this.getParamTriggerMappings()
  },
  methods:{
    getParamTriggerMappings() {
      this.tableData=[]
      axios.get(this.$store.state.api + "triggerParamMappings", {
        headers: {
          Authorization: "Bearer " + this.$store.state.jwt,
        },
      }).then((response) => {
        this.triggeredParamData = response.data.data
      });
    },
  }
};
</script>
<style scoped>
.v-card__title {
  background-color: #bdbdbd00;
}
.theme--light.v-toolbar.v-sheet {
  background-color: #b2dfdb00;
}
.theme--light.v-data-table {
  background-color: #b2dfdb00;
}
</style>
