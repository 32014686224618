<template>
    <div>
        <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper ma-4">Triggers and Alarms</h1> -->
        
        <v-container fluid>
            <v-row no-gutters>
                <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"  cols="auto" class="d-flex">
                    <v-btn
                    class="mx-1"
                    :background-color="$store.getters.getColorPalette().background1ColorCode"
                :color="$store.getters.getColorPalette().accentCode"
                :dark="$store.getters.getColorPalette().isDark"
                outlined
                small
                    text
                        @click="show='createAction'">
                        {{ $store.getters.getTextMap().create_action }}
                        <v-icon
                            right
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().btnborderColorCode"
                            small
                        >mdi-gesture-tap</v-icon>
                    </v-btn>
                    <v-btn
                    :background-color="$store.getters.getColorPalette().background1ColorCode"
              :color="$store.getters.getColorPalette().accentCode"
              :dark="$store.getters.getColorPalette().isDark"
              outlined
              small
                  text
                        @click="show='createTrigger'">
                        {{ $store.getters.getTextMap().create_trigger }}
                        <v-icon
                            right
                            :dark="$store.getters.getColorPalette().isDark" 
                            small
                            :color="$store.getters.getColorPalette().btnborderColorCode"
                        >mdi-bell</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                   <span class="mx-1">
                       <AccessTriggerLogs/>
                   </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="show=='createAction'">
                    <v-card   :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                        <CreateAction  v-on:close="show=''" />
                    </v-card>
                </v-col>
                <v-col v-if="show=='createTrigger'">
                    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                        <CreateTrigger  v-on:close="show=''" />
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="$store.state.actions && $store.state.triggers.length >0">
                <v-col>
                    <TriggerTable/>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="$store.state.actions && $store.state.actions.length >0 ">
                <v-col>
                    <ActionTable/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CreateAction from '@/components/crud_components/CreateAction'
import CreateTrigger from '@/components/crud_components/CreateTrigger'
import ActionTable from '@/components/lists/ActionTable'
import TriggerTable from '@/components/lists/TriggerTable'
import AccessTriggerLogs from '@/components/modals/loggers/AccessTriggerLogs.vue'
export default {
    name:'Alarms',
    components:{
        CreateAction,
        CreateTrigger,
        ActionTable,
        TriggerTable,
        AccessTriggerLogs
    },
    data(){
        return {
            show:''
        }
    },
    mounted() {
        document.title='Enture Actions & Triggers'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>

<style scoped>
.ma-0{
   border:3px solid;
  border-radius:20px;
  /* border-color:#0cab83;
  background-color:#263238 ; */
}
.container{
    margin-top: 1%;
}
.v-sheet.v-card{
    background-color:#4DB6AC ;
}
</style>
