<template>
<v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-row>
        <v-col>
<v-select
                v-if="!loading"
              v-model="form.type"
          :items="types"
          :label="$store.getters.getTextMap().action_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
        </v-row>

 <CreateRelayAction v-if="form.type=='relay'" v-on:close="close" />
<!--<CreateNotificationAction v-if="form.type=='notification'" v-on:close="close" />
<CreateParameterAction v-if="form.type=='parameter'" v-on:close="close" />  -->


         <v-form
            ref="emailForm"
            v-model="emailValid"
            v-if="form.type=='notification'"
            
        >
        <v-row>
            <v-col>
                <v-text-field
                v-if="!loading"
      v-model="form.name"
      :counter="15"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
                v-if="!loading"
              v-model="form.target"
          :items="notificationGroupList"
          :label="$store.getters.getTextMap().notification_group"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                         :color="$store.getters.getColorPalette().submitbtnColor"
                      
                        @click="emailSubmit"
                    >
                      {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().cancelbtnColor"
                      :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form> 
         <v-form
            ref="parameterForm"
            v-model="parameterValid"
            v-if="form.type=='parameter'"
        >
        <v-row>
            
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="paramWrite.workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="paramWrite.device"
          :items="deviceOptions"
          :label="$store.getters.getTextMap().device"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
            </v-col>
        </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="form.name"
                        :counter="15"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="paramWrite.address"
                        :counter="5"
                        :rules="nonNegativeRules"
                        :label="$store.getters.getTextMap().address"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :loading="loading"
                        v-model="paramWrite.offset"
                        :counter="1"
                        :rules="nonNegativeRules"
                        :label="$store.getters.getTextMap().number_of_registers"
                        required  
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="item in valueItems" :key="item">
                    <v-text-field
                    :loading="loading"
                    v-model="paramWrite.values[item]"
                    :counter="5"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().value"
                    required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                         :color="$store.getters.getColorPalette().submitbtnColor"
                    
                        @click="parameterSubmit"
                    >
                        {{ $store.getters.getTextMap().submit  }}
                    </v-btn>
                </v-col>
                 <v-col align="left">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                        {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
 import CreateRelayAction from '@/components/crud_components/actions/CreateRelayAction'
//  import CreateNotificationAction from '@/components/crud_components/actions/CreateNotificationAction'
//  import CreateParameterAction from '@/components/crud_components/actions/CreateParameterAction'
export default {
    name:'CreateAction',
    components:{
        InfoAlert,
         CreateRelayAction,
    //  CreateNotificationAction,
    //  CreateParameterAction
 

    },
    data(){
        return {
            emailValid:false,
            relayValid:false,
            parameterValid:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            emails:'',
            paramWrite:{
                address:null,
                values:[],
                offset:null,
            },
            relayForm:{
                hub:null,
                relay:null,
                state:null
            },
            form:{
                type:'notification',
                target:[]
            },
            types:[
                {label:this.$store.getters.getTextMap().send_notification, value:'notification'},
                {label:this.$store.getters.getTextMap().write_parameter, value:'parameter'},
                {label:this.$store.getters.getTextMap().relays, value:'relay'},
            ],
            relayList:[
                {label:this.$store.getters.getTextMap().relay_1, value:1},
                {label:this.$store.getters.getTextMap().relay_2, value:2},
                {label:this.$store.getters.getTextMap().relay_3, value:3},
                {label:this.$store.getters.getTextMap().relay_4, value:4}
            ],
            objectRules:[
                v => !!v ||this.$store.getters.getTextMap().required
            ],
            nameRules: [
                v => !!v ||  this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) ||  this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 25 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_25_characters,
            ],
            emailRules: [
                v => !!v || this.$store.getters.getTextMap().email_is_required,
                v => /.+@.+\..+,?/.test(v) || this.$store.getters.getTextMap().email_must_be_valid,
            ],
            numberRules:[
                v => !!v || this.$store.getters.getTextMap().required,
                v => /[0-9]+$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_number,
                v => (v && v>=0 && v%1==0)|| this.$store.getters.getTextMap().it_should_be_a_valid_number,
                v => (v && v.length <= 5 && v.length > 0) ||this.$store.getters.getTextMap().number_must_be_less_than_6_digits,
            ],
            nonNegativeRules:[
                v => !!v || this.$store.getters.getTextMap().required,
                v => /[0-9]+$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_number,
                v => (v && v>0 && v%1==0)|| this.$store.getters.getTextMap().it_should_be_a_valid_number,
                v => (v && v.length <= 5 && v.length > 0) || this.$store.getters.getTextMap().number_must_be_less_than_6_digits,
            ],
        }
    },
    computed:{
        notificationGroupList(){
            let op=[]
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
            return op
        },
        valueItems(){
            if(this.paramWrite.offset && Number.isInteger(Number(this.paramWrite.offset)) && Number(this.paramWrite.offset) <= 8){
                let items=[]
                for (let i=0;i<Number(this.paramWrite.offset);i++){
                    items.push(i)
                }
                return items
            }else{
                return []
            }
        },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.paramWrite.workspace && this.paramWrite.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.paramWrite.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        hubList(){
            let op=[]
            for(let i of this.$store.state.hubs){
                op.push({label:i.hub_id,value:i.hub_id})
            }
            return op
        }
    },
    methods:{
       close(){
            this.$emit("close")
        },
        emailSubmit(){
            this.$refs.emailForm.validate()
            if(this.emailValid && this.form.type=='notification'){
                this.loading=true
                let d=Object.assign({}, this.form)
                //d.target=this.emails.replaceAll(' ','').split(',')
                axios.post(this.$store.state.api+'createAction',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshActions')
                            this.$refs.emailForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        parameterSubmit(){
            this.$refs.parameterForm.validate()
            if(this.parameterValid){
                this.loading=true
                let d=Object.assign({}, this.form)
                d.target={}
                d.target['data']=[]
                for(let i=0;i<Number(this.paramWrite.offset);i++){
                    d.target['data'].push(this.paramWrite.values[i])
                }
                d.target['device_id']=this.paramWrite.device.device_id
                d.target['address']=this.paramWrite.address
                d.target['offset']=this.paramWrite.offset
                axios.post(this.$store.state.api+'createAction',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshActions')
                            this.$refs.parameterForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
                

            }
        }
    }

}
</script>