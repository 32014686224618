 <template>
    <v-container>     
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-form
            ref="relayForm"
            v-model="relayValid"
            
            
        >
        <v-row>
            <v-col>
                <v-text-field
               :color="$store.getters.getColorPalette().inputtext2Color"
                v-if="!loading"
      v-model="relayForm.name"
      :counter="25"
      :rules="nameRules"
      label="Name"
      required
    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().inputtext2Color"
                v-if="!loading"
              v-model="relayForm.hub"
          :items="hubList"
          label="Hub"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtext2Color"
                v-if="!loading"
              v-model="relayType"
          :items="relayTypes"
          label="Relay Type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
 :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtext2Color"
                v-if="!loading"
              v-model="relayForm.relay"
          :items="relayList"
          label="Relay"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
                <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                    <v-switch inset v-if="!loading" v-model="relayForm.state" class=" float-left"  ></v-switch>
                </v-col>
            </v-row>
<v-row>
                <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().submitbtnColor"
                        
                        @click="relaySubmit"
                    >
                        Submit
                    </v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                     :color="$store.getters.getColorPalette().cancelbtnColor"
                 :dark="$store.getters.getColorPalette().isDark"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                </v-col>
            </v-row>
</v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateRelayAction',
     props:['action'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.action && this.action.action_id){
            this.api='updateActions'
            this.editMode=true
            this.form=Object.assign({},this.action)
              let target=JSON.parse(this.action.target)
                //  console.log(this.form,'form')
                 this.relayForm['hub']=target['hub']
                 this.relayForm['name']=target['name']
                // this.relayForm['relayType']=target['relayType']
                 this.relayForm['relay']=target['relay']
                this.relayType=target['relayType']
                this.relayForm.state=target['state']==1?true:false
           
      
        }
        else{
            this.api='createAction'
            this.editMode=false
        }
    },
    data(){
        return {
            api:'createAction',
            loading:false,
            relayValid:false,
            showDismissibleAlert:false,
            info:'',
            relayType:null,
            relayForm:{},
            
            relayTypes:[
                {label:'Onboard', value:'onboard'},
                {label:'USB - 1', value:'usb1'},
                {label:'USB - 2', value:'usb2'},
                {label:'USB - 3', value:'usb3'},
                {label:'USB - 4', value:'usb4'},
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    computed:{
        hubList(){
            let op=[]
            for(let i of this.$store.state.hubs){
                op.push({label:i.hub_id,value:i.hub_id})
            }
            return op
        },
        relayList(){
            let op=[]
            if(!this.relayType) return op
            if(this.relayType=='onboard'){
                op=[
                {label:'Relay 1', value:1},
                {label:'Relay 2', value:2},
                {label:'Relay 3', value:3},
                {label:'Relay 4', value:4}
            ]
            }else{
                op=[
                {label:'Relay 1', value:1},
                {label:'Relay 2', value:2},
                {label:'Relay 3', value:3},
                {label:'Relay 4', value:4},
                {label:'Relay 5', value:5},
                {label:'Relay 6', value:6},
                {label:'Relay 7', value:7},
                {label:'Relay 8', value:8}
            ]
            }
            return op
        }
    },
    methods:{
         close(){
            this.$emit("close")
        },
        relaySubmit(){
            this.$refs.relayForm.validate()
            if(this.relayValid ){
                this.loading=true
                let d=Object.assign({}, this.relayForm)
                         // console.log(d,'relay')
                d['state']=d['state']?1:0
                d['relayType']=this.relayType
                //  d.target['data']=[]
                // d.target['state']=this.relayForm.state
                // d.target['relayType']=this.relayType
                //d.target=this.emails.replaceAll(' ','').split(',')
              let payload={
                    
                    type:'relay',
                    name:this.relayForm.name,
                    target:d//JSON.stringify(d)
                }
                //console.log(payload)
                   if(this.action && this.action.action_id){
                this.api='updateActions'
             payload['action_id']=this.action.action_id
                    }else{
                    this.api='createAction'
            }
                axios.post(this.$store.state.api+this.api,payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshActions')
                            this.info='Action created'
                            this.showDismissibleAlert=true
                            this.$refs.relayForm.reset()
                            this.close()
                        }else{
                            
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
    }
}
</script>