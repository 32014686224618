<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card flat  :color="$store.getters.getColorPalette().backgroundColorCode"  :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  >
      <v-card-title
        >{{ trigger.name ? trigger.name : trigger.trigger_id }}
        <v-progress-circular
          indeterminate
          v-if="loading"
          color="primary"
        ></v-progress-circular>
        <v-spacer></v-spacer>
        <span
          v-if="
            $store.state.user &&
            $store.state.settingMode &&
            $store.getters.getAccessByLevels(['engineeraccess'])
          "
        >
        <v-icon
            small
            class="mr-2"
            v-if="
              $store.state.user &&
              $store.state.settingMode &&
              $store.getters.getAccessByLevels(['engineeraccess'])
            "
            @click="showAddTrig = !showAddTrig"
          >
            mdi-plus
          </v-icon>

          <v-icon
            small
            class="mr-2"
            v-if="
              $store.state.user &&
              $store.state.settingMode &&
              $store.getters.getAccessByLevels(['engineeraccess'])
            "
            @click="showEdit = !showEdit"
          >
            mdi-pencil
          </v-icon>
          <DeleteConfirmation
            v-on:confirm="deleteTrigger()"
            title="Delete Confirmation"
            description="Are you sure you want to delete this Trigger?"
          >
            <v-icon
              small
              :color="$store.getters.getColorPalette().deletebtnColor"
            >
              mdi-delete
            </v-icon>
          </DeleteConfirmation>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-row v-if="showEdit">
        <v-col>
          <CreateTrigger :trigger="trigger" v-on:close="showEdit = false" />
        </v-col>
      </v-row>
      <v-row v-if="showAddTrig">
        <v-col>
          <v-form ref="addRuleForm" v-model="ruleValid">
              <v-row>
                <v-col
                  ><v-select
                    v-if="!loading"
                    v-model="ruleForm.operator"
                    :items="staticLists.comparisonOperators"
                    :label="$store.getters.getTextMap().operator"
                    dense
                    filled
                    item-text="label"
                    item-value="value"
                    required
                    :rules="objectRules"
                  ></v-select
                ></v-col>
                <v-col>
                  <v-text-field
      v-model="ruleForm.value"
      :counter="4"
      :rules="[rules.required, rules.num, rules.noSpace]"
      :label="$store.getters.getTextMap().value"
      :loading="loading"
      required
    ></v-text-field>
                </v-col>
                <v-col
                  ><v-btn
                    color="teal"
                    :dark="$store.getters.getColorPalette().isDark"
                    @click="addTriggerRule"
                    >{{$store.getters.getTextMap().add_rule}}</v-btn
                  ></v-col
                >
              </v-row>
            </v-form>
        </v-col>
      </v-row>
      <v-card-subtitle>
        <v-row>
          <v-col>
            <v-simple-table v-if="!loading" height="150px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().sl_no}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().operator}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().value}}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in trigRules" :key="index">
                    <td>{{ index }}</td>
                    <td>{{ item.operator }}</td>
                    <td>{{ item.value }}</td>

                    <td>
                      <DeleteConfirmation
                        v-on:confirm="removeRuleFromTrigger(item)"
                        title="Delete Confirmation"
                        description="Are you sure you want to delete this rule from Trigger?"
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col>
            <strong>{{$store.getters.getTextMap().configured_action}}:</strong> {{ actions.length }}
          </v-col>
          <v-col> <strong>{{$store.getters.getTextMap().id}}:</strong> {{ trigger.trigger_id }} </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col align-self="center">
            <strong>{{$store.getters.getTextMap().targeted_by_value}}:</strong> {{ trigger.operator }}
            {{ trigger.value }}
          </v-col>
          <v-col align-self="center" align="right" cols="1">
            <span
              ><v-btn
                small
                fab
                :dark="$store.getters.getColorPalette().isDark"
                @click="addDialog = !addDialog"
                color="blue"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn></span
            >
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <br />
        <v-row>
          <v-col v-if="addDialog">
            <v-form ref="addForm" v-model="valid">
              <v-row>
                <v-col
                  ><v-select
                    v-if="!loading"
                    v-model="form.action_id"
                    :items="actionList"
                    :label="$store.getters.getTextMap().action"
                    dense
                    filled
                    item-text="label"
                    item-value="value"
                    required
                    :rules="[rules.required]"
                  ></v-select
                ></v-col>
                <v-col
                  ><v-btn
                    color="teal"
                    :dark="$store.getters.getColorPalette().isDark"
                    @click="addActionToTrigger"
                    >{{$store.getters.getTextMap().add_action}}
</v-btn
                  ></v-col
                >
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table v-if="!loading" height="150px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().action_id}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().name}}
</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in actions" :key="item.action_id">
                    <td>{{ item.action_id }}</td>
                    <td>{{ item.name }}</td>

                    <td>
                      <DeleteConfirmation
                        v-on:confirm="removeActionFromTrigger(item)"
                        title="Delete Confirmation"
                        description="Are you sure you want to remove this action from the Trigger?"
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import CreateTrigger from "@/components/crud_components/CreateTrigger";
import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
export default {
  name: "Trigger",
  props: ["trigger"],
  components: {
    InfoAlert,
    CreateTrigger,
    DeleteConfirmation,
  },
  mounted(){
    this.getTrigRules()
  },
  data() {
    return {
      showEdit: false,
      showAddTrig: false,
      ruleValid:false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      addDialog: false,
      valid: false,
      rules:FormRules.rules,
      trigRules:[],
      ruleForm:{},
      staticLists:EntureStaticLists,
      form: {
        action_id: null,
        trigger_id: null,
      },
      objectRules: [FormRules.rules.required],
    };
  },

  computed: {
    actions() {
      let ac = [];

      let actions = this.$store.getters.getActionIDsByTriggerID(
        this.trigger.trigger_id
      );
      //console.log(actions)
      for (let i of actions) {
        let p = this.$store.getters.getActionById(i.action_id);
        if (!p) {
          continue;
        }
        ac.push(p);
      }
      return ac;
    },
    actionList() {
      let op = [];
      for (let i of this.$store.state.actions) {
        if (!this.actions.find((x) => x.action_id == i.action_id)) {
          op.push({
            label: i.name,
            value: i.action_id,
          });
        }
      }
      return op;
    },
  },

  methods: {
    getTrigRules(){
      if(this.trigger && this.trigger.trigger_id){
        axios
          .post(this.$store.state.api + "getRulesByTriggerID", this.trigger, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.trigRules=response.data.data
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });

      }
    },
    addActionToTrigger() {
      this.$refs.addForm.validate();
      if (this.valid) {
        this.loading = true;
        this.form["trigger_id"] = this.trigger.trigger_id;

        axios
          .post(this.$store.state.api + "attachActionToTrigger", this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$store.dispatch("refreshActionMappings");
              this.info = "The Action has been added to the trigger";
              this.showDismissibleAlert = true;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
    addTriggerRule() {
      this.$refs.addRuleForm.validate();
      if (this.ruleValid) {
        this.loading = true;
        this.ruleForm["trigger_id"] = this.trigger.trigger_id;

        axios
          .post(this.$store.state.api + "addTriggerRule", this.ruleForm, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.getTrigRules()
              this.info = "The Action has been added to the trigger";
              this.showDismissibleAlert = true;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
    deleteTrigger() {
      axios
        .post(this.$store.state.api + "deleteTrigger", this.trigger, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshTriggers");
            this.loading = false;
            this.info = "The Trigger has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    removeActionFromTrigger(item) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "removeActionFromTrigger",
          { trigger_id: this.trigger.trigger_id, action_id: item.action_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshActionMappings");
            this.loading = false;
            this.info = "The Action has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    removeRuleFromTrigger(item) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "removeTriggerRule",
          item,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getTrigRules()
            this.loading = false;
            this.info = "The Rule has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
  },
  watch:{
    trigger(){
      this.getTrigRules()
    }
  }
};
</script>
<style scoped>
.v-sheet.v-card {
  background-color: grey;
}
</style>
