<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="triggerForm"
            v-model="valid"          
        >
    <v-row>
        <v-col>
            <v-text-field
          :color="$store.getters.getColorPalette().inputtext2Color"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
        </v-col>
        <v-col v-if="!trigger">
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
   :color="$store.getters.getColorPalette().inputtext2Color"
    v-if="!loading"
          v-model="form.operator"
          :items="operators"
          item-text="label"
          item-value="value"          
          :label="$store.getters.getTextMap().operator"
         :rules="selectRules"
         required
          
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        </v-col>
        <v-col v-if="!trigger">
            
    <v-text-field
    
    :color="$store.getters.getColorPalette().inputtext2Color"
                        :loading="loading"
                        v-model="form.value"
                        :counter="8"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().target_value"
                        required
                    ></v-text-field>
        </v-col>
        <v-col>
            <v-text-field
          :color="$store.getters.getColorPalette().inputtext2Color"
                        :loading="loading"
                        v-model="form.timeout"
                        counter="5"
                        :rules="intRules"
                        :label="$store.getters.getTextMap().wait_time_seconds"
                        required
                    ></v-text-field>
        </v-col>
        
    </v-row>
    <v-row>
        <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                    
                        @click="onSubmit"
                    >
                       {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                      :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
    </v-row>
    </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'CreateTrigger',
    props:['trigger'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.trigger && this.trigger.trigger_id){
            this.api='updateTrigger'
            this.editMode=true
            this.form=Object.assign({},this.trigger)
            
            this.form['timeout']=Number(this.form['timeout'])
        }else{
            this.api='createTrigger'
            this.editMode=false
        }

    },
    data(){
        return {
            api:'createTrigger',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                name:null,
                operator:null,
                value:null,
                actions:[],
                timeout:0
            },
            operators:[
                {label:this.$store.getters.getTextMap().greater_than, value:'>'},
                {label:this.$store.getters.getTextMap().modulus_greater_than, value:'|>'},
                {label:this.$store.getters.getTextMap().lesser_than, value:'<'},
                {label:this.$store.getters.getTextMap().modulus_lesser_than, value:'|<'},
                {label:this.$store.getters.getTextMap().equal_to, value:'=='},
                {label:this.$store.getters.getTextMap().modulus_equal_to, value:'|=='},
                {label:this.$store.getters.getTextMap().greater_than_equal_to, value:'>='},
                {label:this.$store.getters.getTextMap().modulus_greater_than_equal_to, value:'|>='},
                {label:this.$store.getters.getTextMap().lesser_than_equal_to, value:'<='},
                {label:this.$store.getters.getTextMap().modulus_lesser_than_equal_to, value:'|<='},
                {label:this.$store.getters.getTextMap().bit_high, value:'bitHigh'},
                {label:this.$store.getters.getTextMap().bit_low, value:'bitLow'},
            ],
            nameRules: [
                v => !!v || this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 25 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_25_characters,
            ],
            floatRules:[
                v => !!v || this.$store.getters.getTextMap().required,
                v => (v && !isNaN(Number(v))) || this.$store.getters.getTextMap().it_should_be_a_number,
                //v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) ||  this.$store.getters.getTextMap().number_must_be_less_than_8_digits,
            ],
            intRules:[
                v => !!v ||  this.$store.getters.getTextMap().required,
                v => /^[0-9]+$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_positive_whole_number,
                v => (v &&  !isNaN(Number(v))) || this.$store.getters.getTextMap().it_should_be_a_number,
                v => (v && v>=0 )|| this.$store.getters.getTextMap().it_should_be_a_positive,
                v => (v && v.length <= 7 && v.length > 0) || this.$store.getters.getTextMap().number_must_be_less_than_7_digits,
            ],
            msgRules: [
                v => !!v || this.$store.getters.getTextMap().description_is_required,
                v => (v && v.length <= 35 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_35_characters,
            ],
            selectRules:[
                v=> (v!=null) ||  this.$store.getters.getTextMap().required
            ],
            listRules:[
                v=> !!v ||  this.$store.getters.getTextMap().required,
                v => (v && v.length>0) ||  this.$store.getters.getTextMap().you_have_to_select_something
            ],
        }
    },
    computed:{
        actionOptions(){
            let op=[]
          
              let g=this.$store.state.actions
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.action_id, label: i.name })
                }
          return op
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        onSubmit(){
            this.$refs.triggerForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['trigger_id']=this.trigger.trigger_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshTriggers')
                            if(!this.editMode){
                                this.$refs.triggerForm.reset()
                            }
                            
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
            }
    }
}
</script>